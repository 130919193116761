import './ServicesPage.css';
import { ListItem } from '../components/ListItem';
import { Footer } from '../components/ConstantComponent';



const services = [
    {
      imagePath:require('../assets/grid-compliance-study.png'),
      serviceName:"Grid Compliance Study",
      service_desc:`We offer a grid compliance study for wind/solar projects: 
  
      -Steady State study 
      -Dynamic State study 
      -Power Quality Study.
  
      Our team leverages industry-leading tools like <span class="highlight light">PSS/e</span> and <span class="highlight light">PSCAD</span> to deliver exceptional results
      
      <b>Studies conducted by us:</b>
      1. RMS (PSSE) model
      2. EMT (PSCAD) model
3. Simulation results showing the comparison of detailed plant model and equivalent model of the Wind/Solar farm/BESS/Hybrid - (Requirement is only for RMS model)
4. PQ Reactive Capability Curve plot of Wind at POI -
5. Short circuit study results for 3-phase/ single phase fault at POI 
6. DETAILED  modelling in PSCAD for  Power Quality Study from 10 % to 100 % generation in steps of 10 % on Current injection method 
8. Frequency Response Test - Perform frequency response test with dead band of ±0.03 Hz and droop of both 3% and 6%.
9. Dynamic Reactive Power Support (DPRS) at POI - Perform dynamic reactive power test for the following control modes and cases:
10. Ramping Capability
`
    },
    {
      imagePath:require('../assets/protection-study.png'),
      serviceName:"Protection Study",
      service_desc:`The main objective of the study is to provide protection relay setting for the project  through modelling entire Wind/Solar/ plant SLD.
      <b>The following are the broad scope of work involved in the study:</b>
      <ul>
      <li>⦁	Modelling of the entire plant includes overcurrent & earth-fault protection study. </li>
<li>⦁Power system modelling for the proposed sites along with the state and central grid parameters taking into account their present system for the interconnection studies aimed to study for the given year & onwards grid conditions.</li>
<li>⦁	Carryout short circuit study to determine the Short circuit value at each Bus. Identify the short circuit current (phase & earth fault) at the 33kV network. Short circuit study report shall include the fault current contribution from each source or elements or branch. </li>
<li>⦁	Set the overcurrent & Earth fault protection settings.</li>
<li>⦁	Plot the coordinated Time-Current curves </li>
<li>⦁	Report the proposed protection settings.</li>
</ul>`
    },
    {
      imagePath:require('../assets/software.jpg'),
      serviceName:"Software Devlopment",
      service_desc:`We have a highly Experienced team in creating high performance, feature-packed native Mobile, Web and PC Application.
  
      We have built great set of applications for our clients.
      We have great set of products well accepted by clients.`
    },
    {
      imagePath:require('../assets/technical-consultant.jpg'),
      serviceName:"Technical Consultancy & Due-Diligence of Projects",
      service_desc:"Due-Diligence of Wind/Solar Projects to analyse loss levels and complete audit of site."
    },
    {
        imagePath:require('../assets/power-system-consultancy.jpg'),
        serviceName:"Power System Consultancy",
        service_desc:`Providing technical expertise for the design of new Generation plants, transmission lines, and substations.
    
        Assisting with feasibility studies and cost analysis of potential projects.
        Ensuring designs comply with relevant regulations and safety standards
        
        For details on analyzing existing power systems for efficiency, reliability, and capacity.
Identifying bottlenecks and potential weaknesses in the grid.
Forecasting future energy demands based on population growth and industrial trends.
Recommending strategies for system expansion and upgrades.`
      },  
    {
        imagePath:require('../assets/evacuation-study.jpg'),
        serviceName:"Evacuation Study",
        service_desc:`To assess satisfactory evacuation of proposed Wind/Solar power generation from STU/CTU Substation. 
    
        IPR has carried out more than <span class="highlight light">50,000 MW</span> of evacuation study.
        <ul>
        <li>⦁	To perform the load flow studies to examine the opportunities available for transfer of power from given S/S where evacuation study to be conducted. Multiple scenario’s will be created for Summer/Winter Peak & light loads.</li>
  <li>⦁Power system modelling for the proposed sites along with the state and central grid parameters taking into account their present system for the interconnection studies aimed to study for the given year & onwards grid conditions.</li>
  <li>⦁	Collection of any other ongoing developments on the grid network deemed important in the study. </li>
  <li>⦁	To perform steady state power flow analysis in the system.</li>
  <li>⦁	Checking for the adequacy of the existing transmission lines in the State/Central grid lines and substations in the vicinity of the solar/wind farm to evacuate power</li>
  <li>⦁	Indication of system performance in terms of voltage profile and loading of transmission line and transformer loadings. 
  Preparations of reports based on the findings of the feasibility studies, Contingency studies to be carried out for outages of critical transmission lines with N-1 outage conditions during high generation or condition</li>
  </ul>`
    },
    {
        imagePath:require('../assets/optimization.jpg'),
        serviceName:"Wind/Solar Optimization",
        service_desc:`Optimisation includes details modelling & Simulation of entire capacity of  Wind/ Solar plant to analyze the  various technical parameters from the perspective of investment optimisation & technical  loss level assessment.
        
        Also, to know the estimated generation loss in the Wind / Solar farm at different generation levels. 
        ⦁	Energy Loss assessment for Wind/ Solar farm from Turbine to turbine  & Inverter to inverter to Grid substation through Load flow Simulation Study.
        ⦁	Peak Power & Annual Energy Losses Assessment (Dynamic) through Load flow Study for each of the following:
          1)	Wind/Solar Farm line losses estimation from WTG to WTG & Inverter to inverter up to Pooling station
          2)	Sub-station loss
          3)	Evacuation Line losses
          
      <b>Detailed Scope for Optimization:</b>
      ⦁	Base case load flow modelling as per the initial  routing, grouping & type of conductor for the wind/Soalr farm will be carried out.
  ⦁	WTG to WTG, inverter to inverter all the branches/Feeders  & 33 KV Feeder wise, evacuation line loss will be provided for the rated capacity of the turbine. I.e. Peak power loss. 
  ⦁	Details of the entire PCN network i.e. number of KM with various combination of conductor type and circuit type.
  ⦁	Regrouping, rerouting & conductor combination will be modified & will remodel the whole wind farm for optimization for different  substation locations.
  ⦁	Comparison of final peak & average energy loss tabulation for different sub-station locations. 
  ⦁	PDF of SLD with active power flows, voltage & reactive Power flow will be provided.
  ⦁	Peak power loss will be provided for the Entire Wind farm 
  ⦁	Peak loss will be provided with bifurcation of each segment wise ie  
  ⦁	Power loss for each sub-branch of the line i.e. WTG to WTG or tap point 
  ⦁	Power loss for each feeder as combined
  ⦁	Unit transformer loss with bifurcation of No load & full load loss
  ⦁	Sub-station loss
  ⦁	Evacuation loss
  ⦁	As the peak loss may not give much commercial value. Hence Average annual energy loss will be determined based on the given 10 Minutes wind mast data/ Solar one hour data, as per the client input.  
  Annual average energy loss will be provided not on manual calculation but through complete simulation basis of 10 minutes wind mast data of one year.
  
        `
    },
    {
      imagePath:require('../assets/planning.jpg'),
      serviceName:"Transmission Planning and Grid Studies",
      service_desc:"Load flow study Simulation of State transmission Grid network to derive Transmission system congestion."
    },
    
    {
      imagePath:require('../assets/pq-wave.jpg'),
      serviceName:"Power Quality Measurement",
      service_desc:`Measurment of harmonics, direct current injection & flicker as per <span class="highlight light">Grid India/RLDC</span> Guidelines
      
      Central Electricity Authority (Technical Standards for Connectivity to the Grid) (Amendment)
Regulations, 2013 & 2019 mandates wind generating stations, generating stations using inverters, wind - solar photo voltaic hybrid systems and energy storage systems to carry out measurement of Harmonics,Direct Current (DC) Injection and Flicker measurement shall be done at least once in a year`
    },
    {
      imagePath:require('../assets/substation-design.jpg'),
      serviceName:"Substation Design Consultancy",
      service_desc:`Preparation of Basic Concept Report including SLD, Layouts, Sectional drawings, Equipment Specifications, Protection and Metering.`
    },
  ]

const ServicesPage = () => {

    return (
        <div className="page">
            <div className='main-page'>
                <p className='page-heading'>Our Services</p>
                <p className='page-subheading'>Explore our wide range of services in the Power Sector.</p>
                <p className='page-subheading'>We are industry leaders in Power Systems.</p>

                <div className="items-container">
                    <div>
                        <p className='item-heading'>We are Experts in the following services </p>
                        
                        {
                            services.slice(0,5).map((service) =>{
                                return <ListItem 
                                imagePath={service.imagePath} 
                                heading={service.serviceName} 
                                desc={service.service_desc} />
                            })
                        }
                    </div>

                    <div>
                        {
                            services.slice(5,services.length).map((service) =>{
                                return <ListItem 
                                imagePath={service.imagePath} 
                                heading={service.serviceName} 
                                desc={service.service_desc} />
                            })
                        } 
                    </div>
                </div>

            </div>
            
            <Footer />
        </div>
    );
}

export default ServicesPage;