import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './ConstantComponent.css';


export const Navbar = () => {
    const [click, setClick] = useState(false);

    function handleClick() {
      if (window.innerWidth>900)
      {return;}
      
      if (click) {
          document.body.style.overflowY = 'auto'
      }else{
          document.body.style.overflowY = 'hidden'
      }
      console.log("click")

      setClick(!click);
    } 

    return (
        <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to="/" className='logo-link'>
                    <img src="/ipr_logo.png" className='logo navbar' />
                </Link>

                
                <div className='menu-icon' onClick={handleClick}>
                    <span class="material-symbols-outlined">
                        {click?"close":"menu"}
                    </span>
                </div>
                
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={handleClick}>Home</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/products' className='nav-links' onClick={handleClick}>Products</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/services' className='nav-links' onClick={handleClick}>Services</Link>
                    </li>
                    {/*<li className='nav-item'>
                        <Link to='/blog' className='nav-links' onClick={handleClick}>Blog</Link>
    </li>*/}
                    <li className='nav-item'>
                        <Link to='/team' className='nav-links' onClick={handleClick}>Team</Link>
                    </li>

                </ul>

            </div>
        </nav>
        </>
    );
}

export const Footer = () => {

    return (
        <div className='section full-width' id="footer">
          <div className="footer-ipr">
            <img src="/ipr_logo.png" className='logo footer' width="80px" height="80px"/>
            <p> Build the future of <span className='highlight'>Power System</span> with us</p>
          </div>

          <div className="footer-contact">
            <p className="footer-contact-heading">Contact Us</p>

            <table width="fit-content">
              <tr>
                <td>
                  <p><span className='material-symbols-outlined'> apartment </span> </p>
                </td>
                <td><p>Honnam complex, 2nd floor, Ratna Vilas Road, Basavanagudi,Bengaluru</p></td>
              </tr>

              <tr>
                <td>
                  <p><span className='material-symbols-outlined'> phone </span> </p>
                </td>
                <td><p>7543295809</p></td>
              </tr>

              <tr>
                <td>
                  <p><span className='material-symbols-outlined'> mail </span> </p>
                </td>
                <td><p>info@iprtechnologies.com</p></td>
              </tr>
            </table>
          </div>

          <div className='footer-contact'>
            <p className='footer-contact-heading'>Quick Links</p>

            <Link to="/" ><p>Home</p></Link>
            <Link to="/products" ><p>Products</p></Link>
            <Link to="/services" ><p>Services</p></Link>
            <Link to="/team" ><p>Team</p></Link>
            <Link to="/privacy-policy" ><p>Privacy Policy</p></Link>
          </div>
        </div>
    )
}
