import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import './HorizontalScroll.css';

const HorizontalScroll = ({ children ,header}) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
  });
  const x = useTransform(scrollYProgress, [0, 1], ["30%", "-30%"]);

  return (
    <div ref={ref} className="horizontal-scroll-section">
      <div className="horizontal-scroll-view">
        <p className='section-heading'>{header}</p>

        <motion.div className="horizontal-scroll-container" style={{ x }}>
          {children}
        </motion.div>
      </div>
    </div>
  )

}

export default HorizontalScroll;
