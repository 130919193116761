import './ServicesPage.css';
import { ListItem } from '../components/ListItem';
import { Footer } from '../components/ConstantComponent';



const products = [
    {
        heading:'PS & ES',
        desc: `<b>Power Simulation & Engineering Solutions</b>
        This powerful software is utilized by transmission utilities to simulate conductor ampacity, meticulously incorporating various parameters aligned with CEA Norms and IEEE standards.
        It can be used both on mobile and desktop platforms....
        
        <b>Key Features and Benefits:</b>
        <ul>
        <li><b>Accurate Ampacity Simulation:</b> Assess the maximum current-carrying capacity of conductors under diverse operating conditions.</li>
<li><b>Comprehensive Parameter Consideration:</b> Integrates a wide range of factors to ensure the most precise simulations, adhering to industry regulations.</li>
<li><b>Loss Level Determination:</b> Forecast and analyze potential energy losses within the transmission system.</li>
<li><b>Surge Impedance Loading (SIL) Calculation:</b> Evaluate the impact of transient voltage spikes on your conductors.</li>
<li><b>Comparative Conductor Analysis:</b> Effectively compare the performance of different conductor types for informed decision-making.</li>
        </ul>`,
        websiteLink:'http://www.madtechapps.com',
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/pses-logo.png'),
    },
    {
        heading:'M-DLS',
        desc:`<b>MADTech - Distribution Line Simulation</b>
        This software is a valuable tool for distribution utilities, enabling them to simulate losses within high-tension (HT) and low-tension (LT) lines.
        
        <b>Engineers at utilities leverage this software to perform the following:</b>
        <ul>
        <li><b>Voltage and Load Profile Analysis:</b> Assess voltage levels and electrical loads across the distribution network.</li>
<li><b>Informed Decision-Making:</b> Use data-driven insights to support decisions regarding line maintenance and upgrades.</li>
<li><b>Loss Level Determination:</b> Forecast and analyze potential energy losses within the transmission system.</li>
<li><b>BCR (Benefit-Cost Ratio) Calculation:</b>Evaluate the economic feasibility of potential infrastructure improvements.</li>
<li><b>Regulatory Reporting:</b> Generate reports that comply with the requirements of regulatory commissions.</li>
        </ul>`,
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/mdls-logo.png'),
    },
    {
        heading:'IE-Rules',
        desc:`<b>IE Rules or CEA - Safety Regulations</b>
        This mobile app provides easy access to critical electrical safety regulations, empowering electrical engineers to stay informed and ensure adherence to best practices.
        It has Convenient mobile access for on-the-go reference.
        
        <b>It is a Comprehensive Resource for Electrical Engineers</b>`,
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/ie-rules-logo.png'),
    },
    {
        heading:'IE Acts',
        desc:`<b>Indian Electricity Acts</b>
        This mobile app provides electrical engineers with easy access to key electrical engineering references, including the Indian Electricity Act.
        
        <b>Key Features</b>
        <ul>
        <li>Convenient mobile access for on-the-go reference</li>
<li>User-friendly interface for efficient information retrieval</li>
        </ul>`,
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/ie-acts-logo.png'),
    },


    {
        heading:'PCN Design',
        desc:`<b>Windfarm Power Collection & Network Design</b>
        Carryout load flow study of the entire wind farm
Helps to identify Strategic location of wind farm Pooling.
⦁Optimized PCN network design 
⦁Feeder arrangements, 
⦁Size of conductors
⦁Transformer requirement  with NL & Copper loss assessment .
⦁Determines Peak Power Loss component wise.

Based on 10 Min Wind mast data, determines Average annual energy loss for the entire farm.
Interaction with Smartphones, WEB interaction, Excel Sheet`,
        websiteLink:'http://www.pcn.madtechapps.com',
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/pcn-logo.jpeg'),
    },
    {
        heading:'PQ Limits',
        desc:`Measurment of harmonics, direct current injection & flicker as per <span class="highlight light">Grid India/RLDC</span> Guidelines
      
        Central Electricity Authority (Technical Standards for Connectivity to the Grid) (Amendment)
  Regulations, 2013 & 2019 mandates wind generating stations, generating stations using inverters, wind - solar photo voltaic hybrid systems and energy storage systems to carry out measurement of Harmonics,Direct Current (DC) Injection and Flicker measurement shall be done at least once in a year`,
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/pq-limits-logo.png'),
    },
    {
        heading:'PIYF',
        desc:`<b>Power Information on Your Finger Tips</b>
        This software empowers transmission utilities to analyze and optimize the performance of their conductors. It simulates conductor ampacity, considering various factors as outlined by CEA Norms and IEEE standards.
        
        <b>Key Features</b>
        <ul>
        <li><b>Conductor Ampacity Simulation:</b>Assess the maximum current a conductor can safely carry under different conditions.</li>
<li><b>Loss Level Analysis:</b> Estimate potential energy losses within the transmission system.</li>
<li><b>Surge Impedance Loading (SIL) Calculation:</b> Evaluate how transient voltage spikes might impact conductors.</li>
<li><b>Comparative Conductor Analysis:</b>Effectively compare the performance of various conductor types.</li>
        </ul>`,
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/piyf-logo.png'),
    },
    
    {
        heading:'Power Calci',
        desc:`<b>Power Calculator</b>
        The Power Calci mobile app is a valuable companion for electrical engineers. It provides a comprehensive set of electrical engineering calculators in a convenient and easy-to-use mobile format.
        
        <b>Key Features</b>
        <ul>
        <li>Wide range of electrical engineering calculations</li>
<li>User-friendly interface for quick calculations and Mobile accessibility for on-the-go use</li>
        </ul>`,
        //playStoreLink:'https://www.google.com',
        logo:require('../assets/power-calci-logo.png'),
    },
    
]

const ProductsPage = () => {

    return (
        <div className="page">
            <div className='main-page'>
                <p className='page-heading'>Our Prodcuts</p>
                <p className='page-subheading'>Explore our wide range of Software Products for the Power Sector.</p>
                <p className='page-subheading'>We build products of the future.</p>

                <div className="items-container">
                    <div>
                        <p className='item-heading'>These are our powerful Software Products </p>
                        
                        {
                            products.slice(0,4).map((service) =>{
                                return <ListItem 
                                websiteLink={service.websiteLink}
                                playStoreLink={service.playStoreLink}
                                logo={service.logo}
                                heading={service.heading} 
                                desc={service.desc} />
                            })
                        }
                    </div>

                    <div>
                    {
                            products.slice(4,products.length).map((service) =>{
                                return <ListItem 
                                websiteLink={service.websiteLink}
                                playStoreLink={service.playStoreLink}
                                logo={service.logo}
                                heading={service.heading} 
                                desc={service.desc} />
                            })
                        }

                        
                    </div>
                </div>

            </div>
            
            <Footer />
        </div>
    );
}


export default ProductsPage;