import { Footer } from '../components/ConstantComponent';
import './ServicesPage.css';

const PrivacyPolicyPage = () => {

    return (
        <div className='page'>
            <div className='main-page'>
                <p className='page-heading'>Privacy Policy</p>
                <p className='page-subheading'>We do not take any of our customer's data. </p>
                <p className='page-subheading'>We care about your privacy and are committed to protecting it.</p>

                <p>
                    Your privacy is a top priority. This policy explains how we handle any information collected through your use of our websites and apps.<br/>
                    We are committed to not collecting or using any personal information from users. This means we don't gather details that can identify you directly, like your name, email address, or browsing history.<br/>
                    There's a possibility that some technical data (like cookies or basic browser information) might be collected incidentally in the background to ensure the website runs smoothly and offers you a good experience. 
                    This data isn't used for anything else and is kept secure.<br/>
                    We believe in being upfront about our practices.  This policy outlines how we handle information, and by using the website, you acknowledge that you understand this policy.<br/>
                    The internet landscape can change, so we might need to update this policy from time to time. We'll keep you informed by posting any changes on this very page.<br/>
                    If you have any questions about this policy or our handling of information, please don't hesitate to contact us using the following methods:<br/>
                    Email: <span className='highlight light'>info@iprtechnologies.com</span><br/>
                    Phone: <span className='highlight light'>7543295809</span>
                </p>
            </div>

            <Footer/>
        </div>
    )
}

export default PrivacyPolicyPage;