import './ClientScrollEffect.css'; // Import the CSS file with the animation

import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";

import { wrap } from "@motionone/utils";




const ClientScrollEffect = ({ children, baseVelocity = 100 }) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });
  const x = useTransform(baseX, (v) => `${wrap(-10, -61, v)}%`);
  

  useAnimationFrame((t, delta) => {
    let moveBy =  baseVelocity * (delta / 1000);
    moveBy += moveBy * Math.abs(velocityFactor.get());
    baseX.set(baseX.get() + moveBy);
  });

  return(
    <div className="parallax">
      <motion.div className="scroller" style={{ x }}>
        {children}
        {children}
      </motion.div>
    </div>
  )
};

export default ClientScrollEffect;