import React from 'react';
import './HomePage.css';

import ClientScrollEffect from '../components/ClientScrollEffect';
import HorizontalScroll from '../components/HorizontalScroll';
import ExpertiseComponent from '../components/ExpertiseComponent';
import { Footer } from '../components/ConstantComponent';

import { useRef ,useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';



/*******************************************************
 * Data Items
 *******************************************************/

const services = [
  {
    imagePath:require('../assets/grid-compliance-study.png'),
    serviceName:"Grid Compliance Study",
    service_desc:`We offer a grid compliance study for wind/solar projects: 

    -Steady State study 
    -Dynamic State study 
    -Power Quality Study.

    Our team leverages industry-leading tools like <span class="highlight">PSS/e</span> and <span class="highlight">PSCAD</span> to deliver exceptional results`
  },
  {
    imagePath:require('../assets/evacuation-study.jpg'),
    serviceName:"Evacuation Study",
    service_desc:`To assess satisfactory evacuation of proposed Wind/Solar power generation from STU/CTU Substation. 

    IPR has carried out more than <span class="highlight">50,000 MW</span> of evacuation study.`
  },
  {
    imagePath:require('../assets/protection-study.png'),
    serviceName:"Protection Study",
    service_desc:"The main objective of the study is to provide protection relay setting for the project  through modelling entire Wind/Solar/ plant SLD."
  },
  {
    imagePath:require('../assets/optimization.jpg'),
    serviceName:"Wind/Solar Optimization",
    service_desc:`Optimisation includes details modelling & Simulation of entire capacity of  Wind/ Solar plant to analyze the  various technical parameters from the perspective of investment optimisation & technical  loss level assessment.`
  },
  {
    imagePath:require('../assets/software.jpg'),
    serviceName:"Software Devlopment",
    service_desc:`We have a highly Experienced team in creating high performance, feature-packed native Mobile, Web and PC Application.

    We have built great set of applications for our clients.`
  },
  {
    imagePath:require('../assets/technical-consultant.jpg'),
    serviceName:"Technical Consultancy & Due-Diligence of Projects",
    service_desc:"Due-Diligence of Wind/Solar Projects to analyse loss levels and complete audit of site."
  },
  {
    imagePath:require('../assets/planning.jpg'),
    serviceName:"Transmission Planning and Grid Studies",
    service_desc:"Load flow study Simulation of State transmission Grid network to derive Transmission system congestion."
  },
  {
    imagePath:require('../assets/pq-wave.jpg'),
    serviceName:"Power Quality Measurement",
    service_desc:`Measurment of harmonics, direct current injection & flicker as per <span class="highlight">Grid India/RLDC</span> Guidelines`
  },
  {
    imagePath:require('../assets/power-system-consultancy.jpg'),
    serviceName:"Power System Consultancy",
    service_desc:`Providing technical expertise for the design of new Generation plants, transmission lines, and substations.

    Assisting with feasibility studies and cost analysis of potential projects.
    Ensuring designs comply with relevant regulations and safety standards`
  },  
  {
    imagePath:require('../assets/substation-design.jpg'),
    serviceName:"Substation Design Consultancy",
    service_desc:`Preparation of Basic Concept Report including SLD, Layouts, Sectional drawings, Equipment Specifications, Protection and Metering.`
  },
]

const clientImageLinks = [

]


/*******************************************************
 * Components
 *******************************************************/

function ServiceItem({imagePath,serviceName,serviceDesc}) {
  
  return (
    <motion.div className='service-item'  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} 
      transition={{ duration: 1 }} viewport={{ once: true }} >
        <img src={imagePath}
          className='service-image'/>

        <p className='service-name'>{serviceName}</p>
        <p className='service-desc' dangerouslySetInnerHTML={{__html: serviceDesc}}/>

        <Link className='read-more' to="/services">
          <p className='read-more-text'>Read More<span className='material-symbols-outlined'>arrow_forward</span></p>
        </Link>
        
        

    </motion.div>
  )
}

function NavHome() {
  const [activeSection, setActiveSection] = useState(null);
  const sections = ["services", "achievements", "clients","products", "contact"];

  useEffect(() => {
    // Function to determine active section based on scroll position
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const active = sections.find((section) => 
      {
        const element = document.getElementById(section);
        if(element!=null){return scrollY >= (element.offsetTop-200) && scrollY <= element.offsetTop+element.offsetHeight;}
        return false;
      });
      setActiveSection(active);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call initially to set active section
  });

  return (
    <motion.div className='nav-home'  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} 
      transition={{ duration: 2, delay: 1 }} viewport={{ once: true }} >
        
      <ul className="home-menu">
        {
          sections.map((section, index) => (
            <li className={activeSection == section ? 'home-item active' : 'home-item'}>
              <a
                onClick={() =>{document.getElementById(section).scrollIntoView({ behavior: 'smooth', block: 'start'  })}}>{section}</a>
            </li>
          ))
        }
      </ul>
    </motion.div>
  );
}




const HomePage = () => {


  return (
    <div className="home">

        <div className='hero-section' >

          <p className='hero-text'>Building the future of</p>
          <p className='hero-text-main'>Power Systems</p>
        </div>

        <NavHome/>
        
        <div className='section full-width' id="services">
          <HorizontalScroll header={"Our Services"}>
            {services.map((service) =>{
              return <ServiceItem imagePath={service.imagePath} 
                        serviceName={service.serviceName} 
                        serviceDesc={service.service_desc} />
            })}
          </HorizontalScroll>

          <ExpertiseComponent/>
        </div>
        

        <div className='section full-width' id="achievements" >
          <p className='section-heading'>Achievements</p>
          
          <div  style={{position:'relative'}}>
            <p className='graph-number'>18000MW</p>
            <svg className="graph" viewBox="0 0 100 100" preserveAspectRatio='none'>
              <defs>
                <linearGradient id="linearGradient" gradientTransform="rotate(90)">
                  <stop offset="0%" stopColor="#4b97f3" />
                  <stop offset="100%" stopColor="#a8bdea" />
                </linearGradient>
              </defs>

              <motion.path
                d="M 0,100  40,90 50,60 60,20 100,0" 
                fill="none" stroke="url(#linearGradient)" strokeWidth="0.6"
                initial={{pathLength:0}}  whileInView={{ pathLength:1 }} 
                transition={{ duration: 1.2,delay:0.8 }} viewport={{ once: true }}/>

            </svg>
            <p className='graph-text'>
              Grid Compliance Study Expereince of IPR has crossed <span className='highlight'>18000MW</span>
            </p>
          </div>
            
          <div className='achievement-grid' >
            <div className='achievement-item'>
              <svg className='achievement-svg'
                viewBox="2 2 12 12"
                preserveAspectRatio='xMinYMax meet'
              >
              <defs>
                <linearGradient id="achieve-linearGradient">
                  <stop offset="0" stopColor="#086FFF">
                    <animate attributeName="stop-color" values="#086FFF;#f7d5a8;#086FFF"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#f7d5a8">
                    <animate attributeName="offset" values="1;0.9;1"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>

                  <animateTransform attributeName="gradientTransform" type="rotate" 
                    from="0 .5 .5" to="360 .5 .5" dur="5s" repeatCount="indefinite"
                  />
                </linearGradient>
              </defs>
              <rect
                className="svg-polygon"  x="3" y="3" rx="0.5" width="10" height="10"
                fill="none" stroke="url(#achieve-linearGradient)" stroke-width="0.2"
              ></rect>
              </svg>
              <p className='achievement-heading'>Grid Compliance <br/>Study</p>

              <div className='chip achievement'>
                <p>18000+ MW</p>
              </div>

            </div>

            <div className='achievement-item'>
              <svg className='achievement-svg'
                viewBox="2 2 12 12"
                preserveAspectRatio='xMinYMax meet'
              >
              <defs>
                <linearGradient id="achieve-linearGradient">
                  <stop offset="0" stopColor="#086FFF">
                    <animate attributeName="stop-color" values="#086FFF;#f7d5a8;#086FFF"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#f7d5a8">
                    <animate attributeName="offset" values="1;0.9;1"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>

                  <animateTransform attributeName="gradientTransform" type="rotate" 
                    from="0 .5 .5" to="360 .5 .5" dur="5s" repeatCount="indefinite"
                  />
                </linearGradient>
              </defs>
              <rect
                className="svg-polygon inverse"  x="3" y="3" rx="0.5" width="10" height="10"
                fill="none" stroke="url(#achieve-linearGradient)" stroke-width="0.2"
              ></rect>
              </svg>
              <p className='achievement-heading'>Evacuation Study</p>

              <div className='chip achievement'>
                <p>52000+ MW</p>
              </div>

            </div>

            <div className='achievement-item'>
              <svg className='achievement-svg'
                viewBox="0 0 15 15"
                preserveAspectRatio='xMinYMax meet'
              >
              <defs>
                <linearGradient id="achieve-linearGradient">
                  <stop offset="0" stopColor="#086FFF">
                    <animate attributeName="stop-color" values="#086FFF;#f7d5a8;#086FFF"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#f7d5a8">
                    <animate attributeName="offset" values="1;0.9;1"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>

                  <animateTransform attributeName="gradientTransform" type="rotate" 
                    from="0 .5 .5" to="360 .5 .5" dur="5s" repeatCount="indefinite"
                  />
                </linearGradient>
              </defs>
              <rect
                className="svg-polygon inverse"  x="3" y="2" rx="0.5" width="10" height="10"
                fill="none" stroke="url(#achieve-linearGradient)" stroke-width="0.2" transform='rotate(45 7 7)'
              ></rect>
              </svg>
              <p className='achievement-heading'>Optimization <br/>Study</p>

              <div className='chip achievement'>
                <p>60000+ MW</p>
              </div>

            </div>



          </div>

          <div className='achievement-grid' >

            <div className='achievement-item'>
              <svg className='achievement-svg'
                viewBox="2 2 12 12"
                preserveAspectRatio='xMinYMax meet'
              >
              <defs>
                <linearGradient id="achieve-linearGradient">
                  <stop offset="0" stopColor="#086FFF">
                    <animate attributeName="stop-color" values="#086FFF;#f7d5a8;#086FFF"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#f7d5a8">
                    <animate attributeName="offset" values="1;0.9;1"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>

                  <animateTransform attributeName="gradientTransform" type="rotate" 
                    from="0 .5 .5" to="360 .5 .5" dur="5s" repeatCount="indefinite"
                  />
                </linearGradient>
              </defs>
              <rect
                className="svg-polygon inverse"  x="3" y="3" rx="0.5" width="10" height="10"
                fill="none" stroke="url(#achieve-linearGradient)" stroke-width="0.2"
              ></rect>
              </svg>
              <p className='achievement-heading'>Power Quality <br/>Measurment</p>

              <div className='chip achievement'>
                <p>16000 MW / 80 points</p>
              </div>

            </div>

            <div className='achievement-item'>
              <svg className='achievement-svg'
                viewBox="0 0 15 15"
                preserveAspectRatio='xMinYMax meet'
              >
              <defs>
                <linearGradient id="achieve-linearGradient">
                  <stop offset="0" stopColor="#086FFF">
                    <animate attributeName="stop-color" values="#086FFF;#f7d5a8;#086FFF"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#f7d5a8">
                    <animate attributeName="offset" values="1;0.9;1"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>

                  <animateTransform attributeName="gradientTransform" type="rotate" 
                    from="0 .5 .5" to="360 .5 .5" dur="5s" repeatCount="indefinite"
                  />
                </linearGradient>
              </defs>
              <rect
                className="svg-polygon inverse"  x="3" y="2" rx="0.5" width="10" height="10"
                fill="none" stroke="url(#achieve-linearGradient)" stroke-width="0.2" transform='rotate(45 7 7)'
              ></rect>
              </svg>
              <p className='achievement-heading'>Protection <br/>Study</p>

              <div className='chip achievement'>
                <p>3000+ MW</p>
              </div>

            </div>

            <div className='achievement-item'>
              <svg className='achievement-svg'
                viewBox="2 2 12 12"
                preserveAspectRatio='xMinYMax meet'
              >
              <defs>
                <linearGradient id="achieve-linearGradient">
                  <stop offset="0" stopColor="#086FFF">
                    <animate attributeName="stop-color" values="#086FFF;#f7d5a8;#086FFF"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>
                  <stop offset="100%" stopColor="#f7d5a8">
                    <animate attributeName="offset" values="1;0.9;1"
                      dur="2s" repeatCount="indefinite"
                    />
                  </stop>

                  <animateTransform attributeName="gradientTransform" type="rotate" 
                    from="0 .5 .5" to="360 .5 .5" dur="5s" repeatCount="indefinite"
                  />
                </linearGradient>
              </defs>
              <rect
                className="svg-polygon"  x="3" y="3" rx="0.5" width="10" height="10"
                fill="none" stroke="url(#achieve-linearGradient)" stroke-width="0.2"
              ></rect>
              </svg>
              <p className='achievement-heading'>Benchmarking <br/> Study</p>

              <div className='chip achievement'>
                <p>3 WTG + 2 SVG</p>
              </div>

            </div>

            



          </div>

          
          
        </div>
        
        
        <div className='section full-width' id="clients">
          <p className='section-heading clients'>Trusted by</p>


          <ClientScrollEffect baseVelocity={2}>
            <img src={require("../assets/company-1.png")} className='client'/>
            <img src={require("../assets/company-2.png")} className='client'/>
            <img src={require("../assets/company-3.png")} className='client'/>  
          </ClientScrollEffect>
          <ClientScrollEffect baseVelocity={-2}>
            <img src={require("../assets/company-5.png")} className='client'/>
            <img src={require("../assets/company-4.png")} className='client'/>
          </ClientScrollEffect>

        </div>
        
        <div className='section' id="products">
          <p className='section-heading'>Our Products</p>

          <div className="products-container two-column">
            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/pcn-logo.jpeg')} className='product-image'/>
                <p className='service-name'>PCN Design</p>
              </div>
              <p className='service-desc'>This is a powerful software to create a PCN design on mobile and laptop</p>
            </div>
            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/pses-logo.png')} className='product-image'/>
                <p className='service-name'>PS & ES</p>
              </div>
              <p className='service-desc'>This is a powerful software to create a PCN design on mobile and laptop</p>

            </div>
          </div>

          <div className="products-container three-column">
            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/mdls-logo.png')} className='product-image'/>
                <p className='service-name'>M-DLS</p>
              </div>
              <p className='service-desc'>MADTech - Distribution Line Simulation</p>
            </div>

            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/piyf-logo.png')} className='product-image'/>
                <p className='service-name'>PIYF</p>
              </div>
              <p className='service-desc'>Power Information on Your Finger Tips</p>
            </div>

            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/ie-rules-logo.png')} className='product-image'/>
                <p className='service-name'>IE-Rules</p>
              </div>
              <p className='service-desc'>IE Rules or CEA - Safety Regulations</p>
            </div>
          </div>

          <div className="products-container three-column">
            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/ie-acts-logo.png')} className='product-image'/>
                <p className='service-name'>IE Acts</p>
              </div>
              <p className='service-desc'>Indian Electricity Acts</p>
            </div>

            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/power-calci-logo.png')} className='product-image'/>
                <p className='service-name'>Power Calci</p>
              </div>
              <p className='service-desc'>Power Calculator</p>
            </div>

            <div className="product-item">
              <div className='product-header'>
                <img src={require('../assets/pq-limits-logo.png')} className='product-image'/>
                <p className='service-name'>PQ Limits</p>
              </div>
              <p className='service-desc'>Power Quality Limits</p>
            </div>

  
          </div>
          <Link to='/products' className='chip project'>
            <p>Read More</p>
            <span class="material-symbols-outlined">
              arrow_forward
            </span>
          </Link>

          

          
        </div>

        {/*<div className='section' id="projects">
          <p className='section-heading'>Our Projects</p>

          <table className="projects-table">
            <thead>
              <tr>
                <th>Solar Project</th>
                <th>Wind Project</th>
                <th>Change Project</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <ol className='project-list'>
                    <li>Project 1</li>
                    <li>Project 2</li>
                    <li>Project 3</li>
                    <li>Project 4</li>
                  </ol>

                </td>
                <td>
                  <ol className='project-list'>
                      <li>Project 1</li>
                      <li>Project 2</li>
                      <li>Project 3</li>
                      <li>Project 4</li>
                  </ol>
                </td>
                <td>
                  <ol className='project-list'>
                    <li>Project 1</li>
                    <li>Project 2</li>
                    <li>Project 3</li>
                    <li>Project 4</li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>


          <div className='chip project'>
            <p>Read More</p>
            <span class="material-symbols-outlined">
              arrow_forward
            </span>
          </div>


          </div>*/}

        <div className='section' id="contact">
          <p className='section-heading'>Contact Us</p>
          
          <div className="contact-container">
            <div className="contacts">
              <div className="contact-item">
              <img src="/ipr_logo.png" className='logo footer' width="80px" height="80px"/>

                <div className="contact-text-container">
                  <p className='contact-name'>IPR Technologies</p>
                  <p>Phone : 7543295809</p>
                  <p>Email : info@iprtechnologies.com</p>

                </div>
              </div>

            </div>

            <div className="map-container">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.522193356658!2d77.5729566759071!3d12.938402087373833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae159225ff6609%3A0xa88390dbff573897!2sHonnam%20Complex%2C%20Basavanagudi%2C%20Bengaluru%2C%20Karnataka%20560004!5e0!3m2!1sen!2sin!4v1712413482409!5m2!1sen!2sin" 
                className='map' min style={{border:0}} allowfullscreen="" loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"/>
            </div>
          </div>
          
          

        </div>

        <Footer/>

      
    </div>
  );
};



export default HomePage;
