import { useState } from 'react'
import './App.css'

import { Navbar } from './components/ConstantComponent'
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom'

import HomePage from './pages/HomePage'
import ServicesPage from './pages/ServicesPage'
import ProductsPage from './pages/ProductsPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import NotFoundPage from './pages/NotFoundPage'
import TeamPage from './pages/TeamPage'

function App() {


  return (
    <>
      <Router position="relative">

        <Navbar/>
        <Routes>
          <Route path="/" exact element={<HomePage/>}/>
          <Route path="/products" exact element={<ProductsPage/>}/>
          <Route path="/services" exact element={<ServicesPage/>}/>
          <Route path="/privacy-policy" exact element={<PrivacyPolicyPage/>}/>
          <Route path="/team" exact element={<TeamPage/>}/>

          <Route path='*' element={<NotFoundPage/>}/>
        </Routes>


      </Router>
    </>
  )
}

export default App;
