import { Footer } from '../components/ConstantComponent';
import './ServicesPage.css';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {

    return (
        <div className='page'>
            <div className='main-page'>
                <p className='page-heading'>404 Page Not Found</p>
                <p className='page-subheading'>The page you are looking for does not exist in our Website.</p>

                <p className='error'>
                <span class="material-symbols-outlined">
                warning
                </span>
                </p>

                <Link to='/' className='chip project'>
                    <p>Return to Home Page</p>
                    <span class="material-symbols-outlined">
                    arrow_forward
                    </span>
                </Link>
                
            </div>

            <Footer/>
        </div>
    )
}

export default PrivacyPolicyPage;