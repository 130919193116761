import { Footer } from '../components/ConstantComponent';
import './ServicesPage.css';
import { Link } from 'react-router-dom';

const team = [
    {
        fullName:'Kiran V',
        designation:'Founder & CEO',
        image:require('../assets/kiran.jpg'),
        desc:`
        Kiran has rich experience in the power sector. He has worked with over 27 years of experience in the field of wind turbine`
    },
    {
        fullName:'Bhargav K',
        designation:'Founder & Director',
        image:'bhargav.jpg',
        desc:`
        Bhargav has rich experience in the power sector. He has worked with over 20 years of experience in the field of wind turbine`
    },
    {
        fullName:'Subburayan R',
        designation:'BD',
        image:'subburayan.jpg',
        desc:`
        Subburayan has rich experience in the power sector. He has worked with over 20 years of experience in the field of wind turbine`
    },
]

const TeamPage = () => {

    return (
        <div className='page'>
            <div className='main-page'>
                <p className='page-heading'>Our Team</p>
                <p className='page-subheading'>We have a Highly Creative Team with more than <span className='highlight light'>25 years</span> of experience.</p>


                <div className='achievement-grid'>
                    {team.map((member) => {
                        return <div className='list-item'>
                            <img src={member.image} className='list-image'/>
                            <p className='list-name'>{member.fullName}</p>
                            <p className='designation'>{member.designation}</p>

                            <p className='list-desc'>{member.desc}</p>

                            
                        </div>
                    })}
                </div>
                
            </div>

            <Footer/>
        </div>
    )
}

export default TeamPage;