import {React,useState} from 'react';
import './ListItem.css';
import { Link } from 'react-router-dom';
import { motion, Variants } from "framer-motion";

export const cardVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 50,
      rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

export const ListItem = ({imagePath,heading,desc,logo,websiteLink,playStoreLink}) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <motion.div className='list-item'  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} 
        transition={{ duration: 1 }} viewport={{ once: true }} >

        {imagePath &&<img src={imagePath}
        className='list-image'/>}

        <div className='product-header'>
            {logo &&<img src={logo} className='product-image'/>}
            <p className='list-name'>{heading}</p>
        </div>

        
        <p className={'list-desc'+(isExpanded?' expanded':'')} dangerouslySetInnerHTML={{__html: desc}} onClick={toggleExpansion}/>

        {websiteLink &&<a className='w-p-link' href={websiteLink}>
            <span class="material-symbols-outlined">language</span>
            Visit Website            
        </a>}

        {playStoreLink &&<a className='w-p-link' href={playStoreLink}>
            <span class="material-symbols-outlined">play_arrow</span>
            Download App            
        </a>}

        
  
      </motion.div>
    )
};



